import { Router } from '@reach/router'
import React from 'react'
import SEO from '~components/Common/SEO'
import Route from '~utils/Route'
import page from './page'

const Root = page()

const RootPageTemplate = ({ pageContext }) => {
	const { metadata } = pageContext

	return (
		<>
			<SEO
				title={metadata.title}
				description={metadata.description}
				ogTitle={metadata.ogTitle}
				ogDescription={metadata.ogDescription}
				ogImage={metadata.ogImage}
				// ogUrl={`${pageHref}/${slug}`}
				// canonical={`${pageHref}/${slug}`}
			/>
			<Router key={document.location.pathname}>
				<Route path="/" Component={Root} />
				<Route path="/*" Component={Root} />
			</Router>
		</>
	)
}

export default RootPageTemplate
