import React from 'react'

const Route = ({ path, Component, ...props }) => {
	return (
		<div>
			<Component path={path} {...props} />
		</div>
	)
}

export default Route
